const routes = [
  {
    path: ["/", "/home","*"],
    exact: true,
    component: "Home",
  },
  // {
  //   path: ["/about"],
  //   exact: true,
  //   component: "About",
  // },
  // {
  //   path: ["/blogs"],
  //   exact: true,
  //   component: "Blog",
  // },  {
  //   path: ["/careers"],
  //   exact: true,
  //   component: "Careers",
  // },
];

export default routes;
