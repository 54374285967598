import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    /* @font-face {
        font-family: 'Inter', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-family: 'Ubuntu', sans-serif;
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    } */
    body,
    html,
    a {
        /* font-family: 'Motiva Sans Light', sans-serif; */
        font-family: 'Roboto', sans-serif;
        
    }
    .custom-shape-divider-top-1688626102 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1688626102 svg {
    position: relative;
    display: block;
    width: calc(154% + 1.3px);
    height: 75px;
}

.custom-shape-divider-top-1688626102 .shape-fill {
    fill: #FFFFFF;
}
.custom-shape-divider-bottom-1688625899 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1688625994 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1688626054 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1688626054 svg {
    position: relative;
    display: block;
    width: calc(154% + 1.3px);
    height: 75px;
}

.custom-shape-divider-bottom-1688626054 .shape-fill {
    fill: #FFFFFF;
}
    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
        width:100%;
    }
    /* @media only screen and (max-width: 414px) {
          body{
            max-width: 100%;
          }
        }
        @media only screen and (max-width: 890px) {
            body{
            max-width: 100%;
            
          }
        } */

    a:hover {
        color: #D8F3FF;
        background: #fff;

    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Roboto', sans-serif;
        /* font-family: 'Motiva Sans Bold', serif; */
        color: #054F7C;
        font-size: 38px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: "black";
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #054F7C;

        :hover {
            color: #D8F3FF;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;
