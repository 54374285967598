import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import logo from "../../Images/Logo.jpeg";
import Swal from "sweetalert2";
import UnderConstruction from "../../Images/UnderConstruction.jpg";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Chat,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
  P,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const PageHandler = () => {
    Swal.fire({
      title: "Sorry!",
      text: "The page is under Construction.",
      imageUrl: `${UnderConstruction}`,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Under Construction",
    });
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };
  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              {/* <Large to="/">{t("Tell us everything")}</Large> */}
              <Para style={{ paddingBottom: "1rem" }}>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>

              <a aria-label="Chat on WhatsApp" href="https://wa.me/9892195315">
                <SvgIcon
                  src="WhatsAppButtonGreenSmall.svg"
                  width="180px"
                  height="40px"
                />
              </a>
              <a href="mailto:hr@ntcl.io">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              {/* <Empty /> */}
              <Language>{t("Address")}</Language>
              <Para>NAVKAR TECHNOLOGY AND COMMUNICATION LLP</Para>
              <Para>A-block 906 PNTC Tower</Para>
              <Para>Vejalpur, Ahmedabad </Para>
              <Para>Gujarat</Para>
              <a
                href="https://www.google.com/maps/search/?q=NTCL-Navkar Technologies & Communications Limited"
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcon src="googleMaps.svg" width="40px" height="40px" />
              </a>
            </Col>
            {/* <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Policy")}</Title>
              <Large to="/" left="true">
                {t("Application Security")}
              </Large>
              <Large left="true" to="/">
                {t("Software Principles")}
              </Large>
            </Col> */}
            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large left="true" to="/">
                {t("Support Center")}
              </Large>
              <Large left="true" to="/">
                {t("Customer Support")}
              </Large>
            </Col> */}
          </Row>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              {/* <Large left="true" to="/about">
                {t("About")}
              </Large> */}
              <P className="pages" onClick={PageHandler}>
                About
              </P>
              <P className="pages" onClick={PageHandler}>
                Blog
              </P>
              <P className="pages" onClick={PageHandler}>
                Careers & Culture
              </P>
              {/* <Large left="true" to="/blogs">
                {t("Blog")}
              </Large> */}
              {/* <Large left="true" to="/">
                {t("Press")}
              </Large> */}
              {/* <Large left="true" to="/careers">
                {t("Careers & Culture")}
              </Large> */}
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "1rem", paddingBottom: "0" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <img src={logo} alt="logo" width="120px" />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://www.linkedin.com/company/navkar-technology/"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://www.instagram.com/ntcl__/"
                src="instagram.svg"
              />{" "}
              <SocialLink
                href="https://www.facebook.com/people/NTCL/100095499849229/"
                src="facebook2.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
